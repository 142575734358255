/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    medium: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 010 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 019.025 8m4.95 0c0 2.34-1.01 4.236-2.256 4.236S9.463 10.339 9.463 8c0-2.34 1.01-4.236 2.256-4.236S13.975 5.661 13.975 8M16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795"/>',
    },
});
